import UserChannelListView from '@components/inbox/UserChannelListView';
import { useModal } from '@providers/ModalProvider';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { AuthContextValue, useAuthState } from '@src/providers/AuthProvider';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import { AuthScreenType } from '@src/utils/enums';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent, useState } from 'react';

const Menu = dynamic(() => import('@mui/material/Menu'));
const Authentication = dynamic(() => import('@components/modal/authentication/Authentication'));

// Assets
import IconEmailBlackSVG from '/public/ic-email-black.svg';
import IconInboxSVG from '/public/ic-inbox-noti.svg';

export default function InboxButton() {
  const { setModalContent } = useModal();
  const { isAuthenticated } = useAuthState() as AuthContextValue;
  const { unreadUserChannelsQuantity } = useChatState() as ChatContextValue;
  const { isMobile } = useWindowDimensions();
  const hasNewMessage = unreadUserChannelsQuantity > 0;
  const router = useRouter();

  const [anchorElForChat, setAnchorElForChat] = useState<null | HTMLElement>(null);
  const isChatMenuOpen = Boolean(anchorElForChat);

  /* Functions */
  const handleOpenNotification = (event: MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
      return;
    }

    // Open chat menu
    if (isMobile) {
      router.push('/inbox');
      return;
    }

    setAnchorElForChat(event.currentTarget);
  };

  const handleCloseChatMenu = () => {
    setAnchorElForChat(null);
  };

  /* Views */

  return (
    <>
      <button
        className="flex shrink-0 p-1.5 hover:bg-greyscale-g1 rounded-full"
        onClick={handleOpenNotification}
      >
        <div className="relative shrink-0">
          <div className="flex items-center justify-center">
            <IconEmailBlackSVG />
          </div>
          {hasNewMessage && (
            <div className="absolute -top-1 -right-2 text-m font-semibold">
              <div className="flex items-center justify-center w-2 h-2">
                <IconInboxSVG />
              </div>
            </div>
          )}
        </div>
      </button>

      {!isMobile && (
        <Menu
          anchorEl={anchorElForChat}
          id="chat-menu"
          open={isChatMenuOpen}
          onClose={handleCloseChatMenu}
          onClick={handleCloseChatMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,0.20))',
              mt: 1,
              borderRadius: '8px',
              width: '352px',
            },
          }}
        >
          <div className="flex">
            <div className="grow" />
            <div className="grow-0 w-full bg-white p-4 md:p-3 max-h-152 overflow-y-auto">
              <div className="flex items-center mb-3 px-3">
                <span className="text-2xl font-semibold grow">Tin nhắn</span>

                <Link href="/inbox" passHref>
                  <a href="replace" className="flex flex-col items-center">
                    <span className="text-primary-p4 text-s grow-0 font-semibold no-underline">
                      Xem tất cả
                    </span>
                  </a>
                </Link>
              </div>

              <UserChannelListView disableSelectedUserChannel />
            </div>
            <div className="grow" />
          </div>
        </Menu>
      )}
    </>
  );
}
