import { LoadingView } from '@components/loading/LoadingView';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import UserChannelItemView from './UserChannelItemView';
import IconEmptyInboxSVG from '/public/ic-empty-inbox.svg';

export default function UserChannelListView({
  disableSelectedUserChannel,
}: {
  disableSelectedUserChannel?: boolean;
}) {
  const { userChannels, isUserChannelsInitialLoading, selectedUserChannelId } =
    useChatState() as ChatContextValue;

  // Filter user channels with no message
  const nonEmptyUserChannels = userChannels.filter((userChannel) => {
    if (userChannel.id === selectedUserChannelId) return true;
    return userChannel.channel?.messages?.items?.length !== 0;
  });

  return (
    <div className="flex flex-col py-2">
      {/* Loading state */}
      {isUserChannelsInitialLoading && <LoadingView />}
      {/* Display data */}
      {!isUserChannelsInitialLoading && nonEmptyUserChannels.length > 0 && (
        <div className="flex flex-col">
          {nonEmptyUserChannels.map((userChannel) => (
            <UserChannelItemView
              userChannel={userChannel}
              isSelected={
                disableSelectedUserChannel ? false : selectedUserChannelId === userChannel.id
              }
              key={`${userChannel.id}`}
            />
          ))}
        </div>
      )}
      {/* Empty state */}
      {!isUserChannelsInitialLoading && nonEmptyUserChannels.length === 0 && (
        <div className="flex flex-col">
          <div className="flex items-center justify-center">
            <IconEmptyInboxSVG />
          </div>
          <span className="flex font-semibold items-center justify-center mt-4">
            Bạn chưa có tin nhắn
          </span>
        </div>
      )}
    </div>
  );
}
