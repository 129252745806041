import MainAvatar from '@components/profile/MainAvatar';
import { useAuthState } from '@providers/AuthProvider';
import { User, UserChannel } from '@src/API';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import { truncateText } from '@src/utils/formatText';
import dayjs from 'dayjs';

type UserChannelItemViewProps = {
  userChannel: UserChannel;
  isSelected?: boolean;
};

export default function UserChannelItemView(props: UserChannelItemViewProps) {
  const { userChannel, isSelected } = props;
  const { user } = useAuthState();
  const { selectUserChannelByUserChannelId } = useChatState() as ChatContextValue;

  // Get the most recent message
  const userChannelMessages = userChannel.channel?.messages?.items ?? [];
  const userChannelSortedMessages = userChannelMessages.sort((a, b) => {
    if (!a?.publication_date_time || !b?.publication_date_time) return 0;
    const aDateTime = new Date(a.publication_date_time);
    const bDateTime = new Date(b.publication_date_time);
    return bDateTime.getTime() - aDateTime.getTime();
  });
  const mostRecentMessage = userChannelSortedMessages[0] || null;
  // Check if user has read the most recent message
  const isUnread = () => {
    if (mostRecentMessage?.sender_id === user?.id) return false;

    const lastTimeReadMessage = dayjs(userChannel.update_date_time);
    const lastMessageDateTime = dayjs(mostRecentMessage?.publication_date_time);

    if (!lastMessageDateTime.isValid() || !lastTimeReadMessage.isValid()) return false;

    return lastMessageDateTime.isAfter(lastTimeReadMessage);
  };
  // Get the styling class name for the message
  const mostRecentMessageClassName = isUnread()
    ? 'text-s-tall text-content-t100 font-semibold'
    : 'text-s-tall text-content-t300';
  // Get the receiver
  const userChannelsOfChannel = userChannel.channel?.user_channels?.items || [];
  const allUsers = userChannelsOfChannel.map((item) => item?.user) as User[];
  const receiver = allUsers.filter((item) => item?.id !== user?.id)[0];

  /* Functions */
  const handleSelectedUserChannel = async () => {
    if (isSelected) return;
    await selectUserChannelByUserChannelId(userChannel.id);
  };

  /* UIs */

  return (
    <button
      className={`items-center flex w-full px-4 md:px-3 py-3 md:rounded-lg ${
        isSelected ? 'bg-primary-p1' : 'hover:bg-greyscale-g1'
      } `}
      onClick={handleSelectedUserChannel}
    >
      <div className="shrink-0">
        <MainAvatar
          sizeType="md"
          imageSrc={receiver?.profile_picture_url}
          linkUrl={`/users/${receiver?.username || ''}`}
          displayName={receiver?.display_name || ''}
          canOpenProfile={false}
          isOpenNewTab={false}
        />
      </div>
      <div className="flex flex-col w-full ml-3 justify-center items-start">
        <div className="flex w-full space-x-2 justify-between items-center">
          <span className={isUnread() ? 'font-semibold' : 'font-medium'}>
            {truncateText(receiver?.display_name)}
          </span>
          {mostRecentMessage?.publication_date_time && (
            <span className={`text-xs text-content-t300`}>
              {dayjs(new Date(mostRecentMessage?.publication_date_time || '')).fromNow(true)}
            </span>
          )}
        </div>
        <div className="flex space-x-2">
          {(mostRecentMessage?.content?.length || 0) > 0 && (
            <span className={mostRecentMessageClassName}>
              {truncateText(mostRecentMessage?.content, 32)}
            </span>
          )}

          {(mostRecentMessage?.image_url || (mostRecentMessage?.images?.items.length || 0) > 0) && (
            <span className={mostRecentMessageClassName}>
              {mostRecentMessage?.sender_id === user?.id ? 'Bạn đã gửi file.' : 'Đã gửi bạn file.'}
            </span>
          )}
        </div>
      </div>
    </button>
  );
}
