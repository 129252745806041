import CircularProgress from '@mui/material/CircularProgress';

export function LoadingView() {
  return (
    <div className="flex flex-col items-center justify-center w-full min-h-120 h-full">
      <CircularProgress
        color="primary"
        sx={{
          color: '#1976D2',
        }}
      />
    </div>
  );
}
